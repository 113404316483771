
<template>
  <div class="va-row d">
    <div class="flex md1"> </div>
    <div class="flex xs12 md9">
      <vuestic-widget>
        <form
          action=""
          autocomplete="off"
        >
          <div class="">
            <div class="form-group">
              <div class="input-group">
                <multiselect
                    v-model="empresaSelecionada"
                    placeholder="Empresa"
                    track-by="id"
                    label="nome"
                    :options="empresas"
                    :multiple="false"
                    :loading="empresas.length === 0"
                  >
                    <span slot="noResult">Empresa não encontrado.</span>
                    <span slot="noOptions">Lista vazia.</span>
                  </multiselect>
              </div>
            </div>
          </div>
        </form>
        <div class="alinhar">
          <button
            class="btn btn-info btn-micro"
            @click="mudarEmpresa"
            :disabled="!empresaSelecionada"
          >Entra</button>
        </div>
      </vuestic-widget>
    </div>
  </div>
</template>
<script>
export default {

  data() {
    return {
      empresas: [],
      empresaSelecionada: null,
      empressDisponiveis: JSON.parse(localStorage.user).funcionario.empresas,
    };
  },
  created() {
    this.getEmpresas();
  },
  methods: {
    getEmpresas() {
      this.$axios.get('/api/empresa-list/').then((res) => {
        this.empresas = (res.data).filter(this.filtrarEmpresas);
      });
    },
    filtrarEmpresas(empresa) {
      // eslint-disable-next-line eqeqeq
      return this.empressDisponiveis.find(d => d == empresa.id) > -1;
    },
    mudarEmpresa() {
      localStorage.setItem('empresaSelecionada', this.empresaSelecionada.id);
      this.$router.push('/user/dashboard');
    },
  },
};
</script>
<style >
.alinhar {
  display: flex;
  justify-content: flex-end;
}
</style>
